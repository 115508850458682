.myMolecule .mo-mainBench {
  height: calc(100% - 50px);
}

.myMenuBar {
  height: 50px;
  width: 100%;
  min-width: 980px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--activityBar-background);
}

.myMenuBar > .mo-menu {
  box-shadow: none;
  background: var(--activityBar-background);
  color: var(--foreground);
}

.mo-sub-menu {
  z-index: 100 !important;
}

.myMenuBar .mo-menu__item:hover {
  background-color: auto !important;
  border-color: auto !important;
  color: auto !important;
}

.myMenuBar > .mo-menu--horizontal .mo-menu__item {
  min-width: auto;
}

.myMenuBar
  > .mo-menu--horizontal
  > .mo-menu__item
  > .mo-menu__content
  > .mo-menu__label {
  padding: 0 1em;
  flex: none;
  font-size: 12px;
}

.myMenuBar
  > .mo-menu--horizontal
  > .mo-menu__item
  > .mo-menu__content
  > .mo-menu__indicator {
  display: none;
}

.menuBarActions {
  display: flex;
  align-items: center;
  justify-content: center;
}

.menuBarActions button {
  text-align: center;
  min-width: auto;
  margin: 0 0.5em;
}

input[type='file'] {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blinking {
  animation: blinker 1s linear infinite;
}

.acrios-modal {
  color: #cdd0d1 !important;
}

.acrios-modal .modal-content {
  background-color: rgba(35, 36, 42, 0.994) !important;
}
